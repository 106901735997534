@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

//@import "@fontsource/space-grotesk"; // Defaults to weight 400
@import "@fontsource/space-grotesk/400.css"; // Specify weight
@import "@fontsource/space-grotesk/700.css"; // Specify weight

@import "app/global/tnt-breackpoints";

/* You can add global styles to this file, and also import other style files */
body {
  background-color: #ffffff;
}

.page {
  max-width: 1600px;
  margin: auto;

  @media ($xxl) {
    max-width: 1400px;
  }

  @media ($sm) {
    max-width: 95%;
  }
}

.page-content {
  width: 100%;
  padding: 0 5%;

  @media ($lg) {
    padding: 0;
  }
}

.news-list {
  .ant-list-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.ant-layout {
  background: #ffffff;
}

.dashboard-card {
  .ant-list-split {
    .ant-list-item {
      border: none;
    }
  }
}

.page-header {
  .ant-menu-horizontal {
    border: none;
  }
}

.vehicles-content {
  .ant-select-selection-placeholder {
    color: #000000;
  }
}

.prediction-card {
  @media ($xs) {
    .ant-card-body {
      padding: 12px;
    }
  }
}

.menu-lg.ant-menu {
  .ant-menu-item-selected:first-of-type:before {
    display: none;
  }

  .ant-menu-item-selected:first-of-type:after {
    display: none;
  }

  .ant-menu-item:first-of-type:hover:after {
    display: none;
  }

}

.font-2x {
  font-size: 24px;
}

#api-key-spin {
  .ant-spin {
    width: 100%;
  }
}

.settings-page-layout {
  .ant-layout-sider-children {
    height: auto;
  }

  .ant-menu-horizontal {
    border-bottom: none;
  }
}

.settings-menu {
  .ant-layout-sider-trigger {
    position: static;
  }
}

.news-list {
  nz-spin {
    max-width: 100%;
  }
}

.alert-message {
  span {
    color: #fd6a34;
  }
}

.user-menu-item-link {
  .ant-menu-title-content {
    width: 100%
  }
}

.mobile-menu .ant-menu-item:hover {
  border-bottom: none !important;
}

.mobile-menu .ant-menu-item:after {
  border-bottom: none !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.tt-backdrop {
  background: #000000;
  opacity: 0.6;
}

.steps {
  nz-step {

    .ant-steps-item-tail {
      &::after {
        height: 2px;
        margin-left: 18px;
      }
    }
    @media ($sm) {
      .ant-steps-item-tail {
        margin: 0 0 0 4px;
      }
      .ant-steps-item-icon {
        margin-left: 0;
      }
      .ant-steps-item-content {
        width: auto;
      }
    }
    
  }
  nz-step.ant-steps-item-active {
    .dot { color: #01B574;}
  }
  nz-step.ant-steps-item-finish {
    .dot { color: #01B574;}
    .ant-steps-item-tail::after {
      background-color: #01B574;
    }
  }
  nz-step.ant-steps-item-wait {
    .dot {
      border: 1px solid;
      span {
        font-size: 12px;
      }
    }
  }
}

.timeline {
  .ant-timeline-item-head {
    border: 2px solid #01B574;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    display: inline-grid;
    place-content: center;
    span {
      height: 18px;
      width: 18px;
    }
  }
  ul > li:nth-child(1) > .ant-timeline-item-head {
    span {
      height: 14px;
      width: 14px;
    }
  }
  ul > li:last-child > div {
    display: none;
  }
  ul > li:nth-last-child(2) {
    .ant-timeline-item-tail {
      border-image: linear-gradient(to bottom, #01B574, rgb(0,0,0,0));
      border-image-slice: 1;
    }
  }
}

.icon-2x {
  svg {
    width: 28px;
    height: 28px;
  }
}

.icon-4x {
  svg {
    width: 56px;
    height: 56px;
  }
}